import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // 基础定向
  {
    path: "/",
    redirect: "/mine/login",
  },
  // 账户登录
  {
    path: "/mine",
    component: () => import("@/views/mine"),
    children: [
      {
        path: "login",
        component: () => import("@/views/mine/login"),
      },
      {
        path: "register",
        component: () => import("@/views/mine/register"),
      },
    ],
  },
  // 业务页面
  {
    path: "",
    component: () => import("@/components/layout"),
    children: [
      // 平台概况
      {
        path: "home",
        component: () => import("@/views/home"),
      },
      // 订单管理
      {
        path: "order",
        component: () => import("@/views/order"),
        children: [
          {
            path: "orderList",
            component: () => import("@/views/order/orderList"),
          },
        ],
      },
      // 卡券管理
      {
        path: "card",
        component: () => import("@/views/card"),
        children: [
          {
            path: "cardList",
            component: () => import("@/views/card/cardList"),
          },
          {
            path: "cardEdit",
            component: () => import("@/views/card/cardEdit"),
          },
          {
            path: "tagList",
            component: () => import("@/views/card/tagList"),
          },
          {
            path: "orderList",
            component: () => import("@/views/card/orderList"),
          },
          {
            path: "channelCode",
            component: () => import("@/views/card/channelCode"),
          },
          {
            path: "plateformOrder",
            component: () => import("@/views/card/plateformOrder"),
          },
          {
            path: "batch",
            component: () => import("@/views/card/batch"),
          },
          {
            path: "virtual",
            component: () => import("@/views/card/virtual"),
          },
        ],
      },

      // 位置管理
      {
        path: "resource",
        component: () => import("@/views/resource"),
        children: [
          {
            path: "serviceList",
            component: () => import("@/views/resource/serviceList"),
          },
          {
            path: "roomList",
            component: () => import("@/views/resource/roomList"),
          },
          {
            path: "stopUseList",
            component: () => import("@/views/resource/stopUseList"),
          },
          {
            path: "desk",
            component: () => import("@/views/resource/desk"),
          },
        ],
      },
      // 商户设置
      {
        path: "merchant",
        component: () => import("@/views/merchant"),
        children: [
          {
            path: "shopRole",
            component: () => import("@/views/merchant/shopRole"),
          },
          {
            path: "douyin",
            component: () => import("@/views/merchant/douyin"),
          },
          {
            path: "goodsList",
            component: () => import("@/views/merchant/goodsList"),
          },
        ],
      },
      // 门店配置
      {
        path: "shop",
        component: () => import("@/views/shop"),
        children: [
          {
            path: "shopInfo",
            component: () => import("@/views/shop/shopInfo"),
          },
          {
            path: "miniApp",
            component: () => import("@/views/shop/miniApp"),
          },
          {
            path: "mini",
            component: () => import("@/views/shop/mini"),
            children: [
              {
                path: "swipe",
                component: () => import("@/views/shop/mini/swipe"),
              },
              {
                path: "package",
                component: () => import("@/views/shop/mini/package"),
              },
              {
                path: "wifi",
                component: () => import("@/views/shop/mini/wifi"),
              },
              {
                path: "question",
                component: () => import("@/views/shop/mini/question"),
              },
              {
                path: "order",
                component: () => import("@/views/shop/mini/order"),
              },
              {
                path: "share",
                component: () => import("@/views/shop/mini/share"),
              },
            ],
          },
          {
            path: "shopSetting",
            component: () => import("@/views/shop/shopSetting"),
          }
         
          
        ],
      },
      // 账户设置
      {
        path: "account",
        component: () => import("@/views/account"),
      },
      // 团建管理
      {
        path: "party",
        component: () => import("@/views/party"),
        children: [
          {
            path: "partyList",
            component: () => import("@/views/party/partyList"),
          },
        ],
      },
      // 用户管理
      {
        path: "user",
        component: () => import("@/views/user"),
        children: [
          {
            path: "userList",
            component: () => import("@/views/user/userList"),
          },
          {
            path: "userInfo",
            component: () => import("@/views/user/userInfo"),
            children: [
              {
                path: "baseInfo",
                component: () => import("@/views/user/userInfo/baseInfo"),
              },
              {
                path: "userOrderList",
                component: () => import("@/views/user/userInfo/userOrderList"),
              },
              {
                path: "userCardList",
                component: () => import("@/views/user/userInfo/userCardList"),
              },
              {
                path: "goodList",
                component: () => import("@/views/user/userInfo/goodList"),
              },
              {
                path: "balanceInfo",
                component: () => import("@/views/user/userInfo/balanceInfo"),
              },
              {
                path: "charge",
                component: () => import("@/views/user/userInfo/charge"),
              },
              {
                path: "memberLevel",
                component: () => import("@/views/user/userInfo/memberLevel"),
              },
              {
                path: "growthValue",
                component: () => import("@/views/user/userInfo/growthValue"),
              },
              {
                path: "point",
                component: () => import("@/views/user/userInfo/point"),
              }
            ],
          },
        ],
      },
      // 通知管理
      {
        path: "notice",
        component: () => import("@/views/notice"),
        children: [
          {
            path: "weixin",
            component: () => import("@/views/notice/weixin"),
          },
          {
            path: "popup",
            component: () => import("@/views/notice/popup"),
          },
        ],
      },

      // 充值管理
      {
        path: "recharge",
        component: () => import("@/views/recharge"),
        children: [
          {
            path: "packageSetting",
            component: () => import("@/views/recharge/packageSetting"),
          },
          {
            path: "packageOverview",
            component: () => import("@/views/recharge/packageOverview"),
          },
          {
            path: "orderManagement",
            component: () => import("@/views/recharge/orderManagement"),
          },
          {
            path: "replaceRecharge",
            component: () => import("@/views/recharge/replaceRecharge"),
          },
        ],
      },
      // 财务管理
      {
        path: "finances",
        component: () => import("@/views/finances"),
        children: [
          {
            path: "orderWithdrawal",
            component: () => import("@/views/finances/orderWithdrawal"),
          },
        ],
      },
      // 活动管理
      {
        path: "activity",
        component: () => import("@/views/activity"),
        children: [
          {
            path: "imgList",
            component: () => import("@/views/activity/imgList"),
          },
        ],
      },
      // 保洁管理
      {
        path: "clean",
        component: () => import("@/views/clean"),
        children: [
          {
            path: "peopleList",
            component: () => import("@/views/clean/peopleList"),
          },
          {
            path: "orderList",
            component: () => import("@/views/clean/orderList"),
          },
        ],
      },
      // 团购管理
      {
        path: "group",
        component: () => import("@/views/group"),
        children: [
          {
            path: "shopList",
            component: () => import("@/views/group/shopList"),
            children: [
              {
                path: "dianPingList",
                component: () => import("@/views/group/shopList/dianPingList"),
              },
              {
                path: "douYinList",
                component: () => import("@/views/group/shopList/douYinList"),
              },
              {
                path: "meiTuanList",
                component: () => import("@/views/group/shopList/meiTuanList"),
              },
            ],
          },
          {
            path: "cardList",
            component: () => import("@/views/group/cardList"),
            children: [
              {
                path: "dianPingList",
                component: () => import("@/views/group/cardList/dianPingList"),
              },
              {
                path: "douYinList",
                component: () => import("@/views/group/cardList/douYinList"),
              },
              {
                path: "meiTuanList",
                component: () => import("@/views/group/cardList/meiTuanList"),
              },
            ],
          },
          {
            path: "orderList",
            component: () => import("@/views/group/orderList"),
          },
        ],
      },
      // 设备管理
      {
        path: "device",
        component: () => import("@/views/device"),
        children: [
          {
            path: "openList",
            component: () => import("@/views/device/openList"),
          },
          {
            path: "deviceList",
            component: () => import("@/views/device/deviceList"),
            children: [
              {
                path: "doorList",
                component: () => import("@/views/device/deviceList/doorList"),
              },
              {
                path: "powerList",
                component: () => import("@/views/device/deviceList/powerList"),
              },
              {
                path: "playerList",
                component: () => import("@/views/device/deviceList/playerList"),
              },
              {
                path: "cabinetList",
                component: () =>
                  import("@/views/device/deviceList/cabinetList"),
              },
            ],
          },
        ],
      },
      // 运营管理
      {
        path: "serve",
        component: () => import("@/views/serve"),
        children: [
          {
            path: "servePeopleList",
            component: () => import("@/views/serve/servePeopleList"),
          },
          {
            path: "serveOrderList",
            component: () => import("@/views/serve/serveOrderList"),
          },
          {
            path: "mallOrderList",
            component: () => import("@/views/serve/mallOrderList"),
          },
          {
            path: "salesList",
            component: () => import("@/views/serve/salesList"),
            children: [
              {
                path: "goodsSalesList",
                component: () =>
                  import("@/views/serve/salesList/goodsSalesList"),
              },
              {
                path: "packageSalesList",
                component: () =>
                  import("@/views/serve/salesList/packageSalesList"),
              },
            ],
          },
        ],
      },
      // 商城管理
      {
        path: "mall",
        component: () => import("@/views/mall"),
        children: [
          {
            path: "mallGoodsList",
            component: () => import("@/views/mall/mallGoodsList"),
          },
          {
            path: "mallTypeList",
            component: () => import("@/views/mall/mallTypeList"),
          },
          {
            path: "mallShopList",
            component: () => import("@/views/mall/mallShopList"),
          },
          {
            path: "recommendList",
            component: () => import("@/views/mall/recommendList"),
          },
          {
            path: "mallCabinetList",
            component: () => import("@/views/mall/mallCabinetList"),
          },
          {
            path: "mallOrder",
            component: () => import("@/views/mall/mallOrder"),
            children: [
              {
                path: "cabinetOrderList",
                component: () =>
                  import("@/views/mall/mallOrder/cabinetOrderList"),
              },
              {
                path: "deliveryOrderList",
                component: () =>
                  import("@/views/mall/mallOrder/deliveryOrderList"),
              },
            ],
          },
          {
            path: "pointOrder",
            component: () => import("@/views/mall/pointOrder"),
          },
        ],
      },
      // 系统设置
      {
        path: "setting",
        component: () => import("@/views/setting"),
        children: [
          {
            path: "menuList",
            component: () => import("@/views/setting/menuList"),
          },
          {
            path: "myselfInfo",
            component: () => import("@/views/setting/myselfInfo"),
          },
          {
            path: "roleList",
            component: () => import("@/views/setting/roleList"),
          },
          {
            path: "userList",
            component: () => import("@/views/setting/userList"),
          },
        ],
      },
    ],
  },
  // 微信授权
  {
    path: "/wexinAuto",
    component: () => import("@/views/wexinAuto"),
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});
export default router;
